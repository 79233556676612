import { NvAxios, useMutation } from '@novaera/core';
import { useQueryClient } from '@tanstack/react-query';
import { ADMIN_WORKSPACES_PREFIX } from '../constants';
import { QUERY_KEYS_ADMIN_WORKSPACES } from '../keys';

const deleteWorkspaceBySubdomain = async (subdomains: string) => {
  await NvAxios.delete(`${ADMIN_WORKSPACES_PREFIX}/${subdomains}`);
};

export const useDeleteWorkspaceBySubdomain = () => {
  const cache = useQueryClient();

  return useMutation(deleteWorkspaceBySubdomain, {
    onSuccess: () => {
      cache.invalidateQueries(QUERY_KEYS_ADMIN_WORKSPACES.all);
    },
  });
};
